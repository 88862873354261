export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const currentYear = new Date().getFullYear();

export const storageKey = {
    USER_ID: 'userId',
    USER_NIP: 'userNIP',
    USER_INFO: 'userInfo',
    USER_ROLE_NAME: 'userRoleName',
    USER_NAME: 'userName',
    USER_TOKEN: 'userToken',
    USER_PERMISSION: '',
    USER_UNKER: 'userUnker',
    USER_GENDER: 'userGender',
    USER_NIK: 'userNIK',
    USER_NO_KK: 'userNoKK',
    USER_STATUS: 'userStatus',
    USER_WORK_TIME: 'userWorkTime',
    USER_CLTN: 'userCLTN',
    IS_LOGGED_ID: 'isLoggedIn'
};
