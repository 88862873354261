/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { storageKey } from 'store/constant';
import PropTypes from 'prop-types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const AuthGuard = ({ children }) => {
    const [exp, setExp] = useState(null);
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem(storageKey.USER_INFO));

    useEffect(() => {
        if (userInfo && userInfo.exp) {
            setExp(userInfo.exp);
        }
    }, [userInfo]);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (!localStorage.getItem('isLoggedIn') || (exp && currentTimestamp >= exp)) {
                navigate('login', { replace: true });
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [exp, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthGuard;
