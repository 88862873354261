/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Button, Grid } from '@mui/material';
import { ButtonShadow } from 'style/button';

const ButtonBatalOnModal = ({ error, onClick, tutup, disabled }) => (
    <Grid textAlign="right">
        <Button
            disableElevation
            variant="outlined"
            type="button"
            color={error ? 'error' : 'secondary'}
            size="small"
            sx={{ ...ButtonShadow(), borderRadius: '12px' }}
            onClick={onClick}
            disabled={disabled ?? false}
        >
            {tutup ? 'Tutup' : 'Batal'}
        </Button>
    </Grid>
);

export default ButtonBatalOnModal;
