const ModalStyle1 = () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '415px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '24px'
});

export default ModalStyle1;
