import { Card, CardContent, Fade, Modal, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IconLock } from '@tabler/icons';
import ButtonBatalOnModal from 'components/button/ButtonBatalOnModal';

import ModalStyle1 from 'style/modal/ModalStyle1';
import RoundIconCard from 'ui-component/cards/RoundIconCard';

// eslint-disable-next-line react/prop-types
const LupaKataSandi = ({ open, handleClose }) => {
    const theme = useTheme();

    return (
        <Modal open={open} onClose={handleClose} closeAfterTransition>
            <Fade in={open}>
                <Card sx={ModalStyle1}>
                    <CardContent>
                        <Stack alignItems="center" spacing={3}>
                            <Stack alignItems="center">
                                <RoundIconCard iconPrimary={IconLock} color={theme.palette.secondary.dark} />
                                <Typography variant="h5" sx={{ color: 'rgba(57, 57, 57, 0.5)', paddingTop: 1, fontWeight: 400 }}>
                                    Lupa Kata Sandi?
                                </Typography>
                            </Stack>

                            <Typography variant="h5" fontWeight="700">
                                Silahkan menghubungi Helpdesk Pustik KP ext. 5555
                            </Typography>

                            <ButtonBatalOnModal tutup onClick={handleClose} />
                        </Stack>
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
};

export default LupaKataSandi;
