import { styled } from '@mui/material/styles';
import backgroundlogo from 'assets/images/backgrounds/loginBackground.svg';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper = styled('div')(() => ({
    position: 'relative',
    minHeight: '100vh',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: `url(${backgroundlogo})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(3px)',
        zIndex: -1
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(49, 112, 143, 0.7)',
        zIndex: -1
    }
}));

export default AuthWrapper;
