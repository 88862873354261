import PropTypes from 'prop-types';

// material-ui
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

// styles
const IconWrapper = styled('div')({
    width: '50px',
    height: '50px',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
    justifyContent: 'center',
    '& span:first-child': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        opacity: '0.2',
        zIndex: '1'
    },
    '& span': {
        width: '20px',
        height: '20px'
    },
    '& svg': {
        width: '20px',
        height: '20px',
        position: 'relative',
        zIndex: '5'
    }
});

// ============================|| ROUND ICON CARD ||============================ //

const RoundIconCard = ({ iconPrimary, color }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

    return (
        <Grid item>
            <IconWrapper>
                <span style={{ background: color }} />
                <span style={{ color }}>{primaryIcon}</span>
            </IconWrapper>
        </Grid>
    );
};

RoundIconCard.propTypes = {
    iconPrimary: PropTypes.object,
    color: PropTypes.string
};

export default RoundIconCard;
