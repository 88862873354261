import { Divider, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import logo from 'assets/images/logoKemlu/logoKemluVertikal.svg';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, ...other }) => (
    <MainCard {...other} sx={{ minHeight: '500px' }}>
        <Grid item xs={7}>
            <Stack direction="column" sx={{ alignItems: 'center' }}>
                <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center', mb: 2.5 }}>
                    <img src={logo} alt="Kemlu Logo" width="200" />
                    <Divider orientation="horizontal" variant="middle" sx={{ width: 220, marginY: 2 }} />
                    <Typography textAlign="center" variant="h2" color="#31708F">
                        Layanan Kepegawaian
                    </Typography>
                </Stack>

                <div>{children}</div>
            </Stack>
        </Grid>
    </MainCard>
);

AuthCardWrapper.propTypes = {
    children: PropTypes.node
};

export default AuthCardWrapper;
