/* eslint-disable import/prefer-default-export */
import { useTheme } from '@mui/material/styles';

export const ButtonSuccess = () => {
    const theme = useTheme();

    return {
        width: '100%',
        backgroundColor: theme.palette.success.dark,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
        },
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
    };
};

export const ButtonWarning = () => {
    const theme = useTheme();

    return {
        width: '100%',
        backgroundColor: theme.palette.warning.dark,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
        },
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
    };
};

export const ButtonError = () => {
    const theme = useTheme();

    return {
        width: '100%',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
        },
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
    };
};

export const ButtonInfo = () => ({
    width: '100%',
    px: 2,
    backgroundColor: 'rgba(64, 139, 252, 1)',
    '&:hover': {
        backgroundColor: 'rgba(64, 139, 252, 1)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
    },
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
});

export const ButtonShadow = () => ({
    px: 2,
    '&:hover': {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
    },
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
});
