import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const MasterRole = Loadable(lazy(() => import('views/pages/masterData/masterRole')));
const RoleForm = Loadable(lazy(() => import('views/pages/masterData/masterRole/form/AddRoleForm')));
const KGB = Loadable(lazy(() => import('views/pages/KGB/pengajuanKGB')));
const PengesahanKGB = Loadable(lazy(() => import('views/pages/KGB/pengesahanKGB')));
const PengesahanCuti = Loadable(lazy(() => import('views/pages/Cuti/pengesahanCuti/permohonanCuti')));
const PengesahanCLTN = Loadable(lazy(() => import('views/pages/Cuti/pengesahanCuti/cltn')));
const LogActivity = Loadable(lazy(() => import('views/pages/logActivity')));
const MasterCutiPegawai = Loadable(lazy(() => import('views/pages/masterData/masterCutiPegawai')));
const MasterPangkatGolongan = Loadable(lazy(() => import('views/pages/masterData/masterGaji')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const AuthGuard = Loadable(lazy(() => import('utils/route-guard/AuthGuard')));
const FormPermohonanCuti = Loadable(lazy(() => import('components/form/permohonan/FormPermohonanCuti')));
const FormPermohonanCLTN = Loadable(lazy(() => import('components/form/permohonan/FormPermohonanCLTN')));
const FormPermohonanKGB = Loadable(lazy(() => import('components/form/permohonan/FormPermohonanKGB')));
const CjKelahiran = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/kelahiran')));
const CjPengangkatanAnak = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/pengangkatanAnak')));
const CjPerceraian = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/perceraian')));
const CjKematian = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/kematian')));
const CjPernikahan = Loadable(lazy(() => import('views/pages/cacahJiwa/pengajuan/pernikahan')));
const IzinMenikah = Loadable(lazy(() => import('views/pages/izinMenikah/pengajuan')));
const PengesahanCj = Loadable(lazy(() => import('views/pages/cacahJiwa/pengesahan')));
const FormPermohonanCj = Loadable(lazy(() => import('components/form/permohonan/FormPermohonanCj')));
const PengesahanIzinMenikah = Loadable(lazy(() => import('views/pages/izinMenikah/pengesahan')));
const FormPermohonanIzinMenikah = Loadable(lazy(() => import('components/form/permohonan/izinMenikah/FormPermohonanIzinMenikah')));
const PengajuanCuti = Loadable(lazy(() => import('views/pages/Cuti/pengajuanCuti')));
const RiwayatPengajuanCuti = Loadable(lazy(() => import('views/pages/Cuti/pengajuanCuti/riwayatPengajuan')));
const PageNotFound = Loadable(lazy(() => import('views/pages/maintenance/PageNotFound')));
const MasterUser = Loadable(lazy(() => import('views/pages/masterData/masterUser')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/',
        //     element: <KGB />
        // },

        {
            path: '/pengajuan-cacah-jiwa/pernikahan',
            element: <CjPernikahan />
        },
        {
            path: '/pengajuan-cacah-jiwa/kematian',
            element: <CjKematian />
        },
        {
            path: '/pengajuan-cacah-jiwa/kelahiran',
            element: <CjKelahiran />
        },
        {
            path: '/pengajuan-cacah-jiwa/pengangkatan-anak',
            element: <CjPengangkatanAnak />
        },
        {
            path: '/pengajuan-cacah-jiwa/perceraian',
            element: <CjPerceraian />
        },
        {
            path: '/pengesahan-cacah-jiwa/kelahiran',
            element: <PengesahanCj val={0} />
        },
        {
            path: '/pengesahan-cacah-jiwa/pengangkatan-anak',
            element: <PengesahanCj val={1} />
        },
        {
            path: '/pengesahan-cacah-jiwa/pernikahan',
            element: <PengesahanCj val={2} />
        },
        {
            path: '/pengesahan-cacah-jiwa/perceraian',
            element: <PengesahanCj val={3} />
        },
        {
            path: '/pengesahan-cacah-jiwa/kematian',
            element: <PengesahanCj val={4} />
        },
        {
            path: '/pengesahan-cacah-jiwa/kelahiran/:id',
            element: <FormPermohonanCj />
        },
        {
            path: '/pengesahan-cacah-jiwa/pengangkatan-anak/:id',
            element: <FormPermohonanCj />
        },
        {
            path: '/pengesahan-cacah-jiwa/pernikahan/:id',
            element: <FormPermohonanCj />
        },
        {
            path: '/pengesahan-cacah-jiwa/perceraian/:id',
            element: <FormPermohonanCj />
        },
        {
            path: '/pengesahan-cacah-jiwa/kematian/:id',
            element: <FormPermohonanCj />
        },

        {
            path: '/pengajuan-kenaikan-gaji-berkala',
            element: <KGB />
        },
        {
            path: '/pengesahan-kenaikan-gaji-berkala',
            element: <PengesahanKGB />
        },
        {
            path: '/pengesahan-kenaikan-gaji-berkala/:id',
            element: <FormPermohonanKGB />
        },

        {
            path: '/pengajuan-izin-menikah',
            element: <IzinMenikah />
        },
        {
            path: '/pengesahan-izin-menikah',
            element: <PengesahanIzinMenikah />
        },
        {
            path: '/pengesahan-izin-menikah/:id',
            element: <FormPermohonanIzinMenikah />
        },

        {
            path: '/pengajuan-cuti/cuti-tahunan',
            element: <PengajuanCuti cuti="tahunan" />
        },
        {
            path: '/pengajuan-cuti/cuti-besar',
            element: <PengajuanCuti cuti="besar" />
        },
        {
            path: '/pengajuan-cuti/cuti-sakit',
            element: <PengajuanCuti cuti="sakit" />
        },
        {
            path: '/pengajuan-cuti/cuti-melahirkan',
            element: <PengajuanCuti cuti="melahirkan" />
        },
        {
            path: '/pengajuan-cuti/cuti-karena-alasan-penting',
            element: <PengajuanCuti cuti="penting" />
        },
        {
            path: '/pengajuan-cuti/cuti-di-luar-tanggungan-negara',
            element: <PengajuanCuti cuti="cltn" />
        },
        {
            path: '/riwayat-pengajuan-cuti',
            element: <RiwayatPengajuanCuti />
        },

        {
            path: '/pengesahan-cuti/tahunan',
            element: <PengesahanCuti val={0} />
        },
        {
            path: '/pengesahan-cuti/besar',
            element: <PengesahanCuti val={1} />
        },
        {
            path: '/pengesahan-cuti/sakit',
            element: <PengesahanCuti val={2} />
        },
        {
            path: '/pengesahan-cuti/melahirkan',
            element: <PengesahanCuti val={3} />
        },
        {
            path: '/pengesahan-cuti/karena-alasan-penting',
            element: <PengesahanCuti val={4} />
        },
        {
            path: '/pengesahan-cuti/tahunan/:id',
            element: <FormPermohonanCuti />
        },
        {
            path: '/pengesahan-cuti/besar/:id',
            element: <FormPermohonanCuti />
        },
        {
            path: '/pengesahan-cuti/sakit/:id',
            element: <FormPermohonanCuti />
        },
        {
            path: '/pengesahan-cuti/melahirkan/:id',
            element: <FormPermohonanCuti />
        },
        {
            path: '/pengesahan-cuti/karena-alasan-penting/:id',
            element: <FormPermohonanCuti />
        },
        {
            path: '/pengesahan-cuti/cltn',
            element: <PengesahanCLTN val={0} />
        },
        {
            path: '/pengesahan-cuti/perpanjangan-cltn',
            element: <PengesahanCLTN val={1} />
        },
        {
            path: '/pengesahan-cuti/lapor-selesai-cltn',
            element: <PengesahanCLTN val={2} />
        },
        {
            path: '/pengesahan-cuti/cltn-aktif',
            element: <PengesahanCLTN val={3} />
        },
        {
            path: 'pengesahan-cuti/cltn/:id',
            element: <FormPermohonanCLTN />
        },
        {
            path: 'pengesahan-cuti/perpanjangan-cltn/:id',
            element: <FormPermohonanCLTN />
        },
        {
            path: 'pengesahan-cuti/lapor-selesai-cltn/:id',
            element: <FormPermohonanCLTN />
        },
        {
            path: 'pengesahan-cuti/cltn-aktif/:id',
            element: <FormPermohonanCLTN />
        },

        {
            path: '/master-data/master-cuti-pegawai',
            element: <MasterCutiPegawai />
        },
        {
            path: '/master-data/master-kgb',
            element: <MasterPangkatGolongan />
        },
        {
            path: '/master-data/master-role',
            element: <MasterRole />
        },
        {
            path: '/master-data/master-role/form',
            element: <RoleForm />
        },
        {
            path: '/master-data/master-user',
            element: <MasterUser />
        },

        {
            path: '/log-aktivitas',
            element: <LogActivity />
        },
        {
            path: '/page-not-found',
            element: <PageNotFound />
        }
    ]
};

export default MainRoutes;
