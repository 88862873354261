// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const THEME_LOCALE = '@customization/THEME_LOCALE';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
